import React, { useState, useEffect, useRef } from 'react';
import './adControlBar.scss';
import { PLAYER_EVENTS_LIST } from '@airtel-tv/constants';
import { subscribe } from '../../../../tv/src/utilities/Pubsub';
import PlayerLoaderComponent from '../../../../web/src/modules/playbackComponents/components/player-controls/PlayerLoaderComponent';
import SkipAdButtonComponent from '../../../../tv/src/modules/playbackComponents/components/overlays/cta/SkipAdCtaComponent';

// eslint-disable-next-line arrow-body-style
const SdkAdControlBar = (props) => {
    const {
        playerEvents,
        isAdLoading,
        skipOffset,
        adSkip
    } = props;
    const thisRef = useRef({
        eventSubscribed: null,
        playerEvents: null,
    }).current;

    const [adProgressData, setAdProgressData] = useState(0);
    const [adSkipOffset, setadSkipOffset] = useState(0);

    useEffect(() => {
        playerEvents?.emitter.on(PLAYER_EVENTS_LIST.AD_PROGRESS, (e) => {
            setAdProgressData(e);
        });
        const adSkipOffset = skipOffset();
        setadSkipOffset(adSkipOffset);
    },[]);

    const getRemainingAdTime = () => {
        let { currentTime, duration } = adProgressData;
        currentTime = Math.floor(currentTime);
        duration = Math.floor(duration);
        const timeRemaining = Math.ceil(duration - currentTime);
        let min = Math.floor(timeRemaining / 60);
        let sec = timeRemaining % 60;
        min = min < 10 ? `0${min}` : min;
        sec = sec < 10 ? `0${sec}` : sec;
        return `${min}:${sec}`;
    };


    const getPosnStyle = () => {
        let { currentTime, duration } = adProgressData;
        if (!(currentTime && duration)) {
            return {
                transform: `scaleX(0)`,
            };
        }
        // currentTime = Math.floor(currentTime);
        duration = Math.floor(duration);

        const position = currentTime * 100 / duration;
        return {
            transform: `scaleX(${position / 100})`,
            // transition: `transform 0.5s linear 0.1s`,
        };
    };

    const {
        adPosition, totalAds,
        currentTime, duration,
    } = adProgressData;
    return ( <div
            className={`ad-ui-control ads-control-v2`}
        > 
        { !isAdLoading && <div className='ads-tv-gradient'></div> }
        { isAdLoading ? <div className='ad-center-spinner'><PlayerLoaderComponent /></div>: 
        adPosition && totalAds ?
        <>
            <div className='m-web-ads-gradient'></div>
            {totalAds > 1 && <div className='number-of-ads text-20 mobile-text-10 text-bold'>{`Ad ${adPosition} of ${totalAds}`}</div> }
            <div
                className="ui-video-seek-slider"
                style={{ width: '100%' }}
            >
                <div
                    className="track"
                    role="progressbar"
                    tabIndex={0}
                    ref={(ref) => {
                        //this.track = ref;
                    }}
                    id="seekbar"
                >
                    <div
                        id="ad-main-seekbar"
                        className="main"
                    >
                        <div
                            className="connect"
                            style={getPosnStyle()}
                        />
                    </div>
                </div>
                { currentTime && duration ? <div className="remaining-timer text-16 mobile-text-12 text-bold">{getRemainingAdTime()}</div> : null }
            </div>
            <div className={`ads-msg text-20 mobile-text-12 text-bold  mobile-text-normal-12 ${totalAds <= 1 ? 'message-left' : ''}`}>
                This video will play after this ad.
            </div>
            {adSkipOffset  > 0 && <SkipAdButtonComponent 
                onClickFn={adSkip}
                adSkipOffset={adSkipOffset}
            /> }</> : null } 
        </div>
    )
}

export default SdkAdControlBar;
