/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-loop-func */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import lodashGet from 'lodash/get';
import withStyles from 'isomorphic-style-loader/withStyles';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { AD_TYPE } from '@airtel-tv/constants/GlobalConst';
import styles from './adControlBar.scss';
import ClientAdControlBar from './ClientAdControlBar';
import ServerAdControlBar from './ServerAdControlBar';
import { toggleFullScreen } from '../../actions/PlaybackActions';


const AdControlBar = (props) => {
    // console.log('====> ads AdControlBar', props);
    if (!props.adsPlayingFlag) {
        return null;
    }
    const { CLIENT_SIDE_AD = 'CLIENT_SIDE_AD' } = AD_TYPE;
    if (props.adType === CLIENT_SIDE_AD) {
        return (<ClientAdControlBar {...props} />);
    }
    return (<ServerAdControlBar {...props} />);
};

const mapStateToProps = (state) => {
    const {
        playbackDetails,
    } = state;
    const adsPlayingFlag = lodashGet(playbackDetails, 'adsPlayingFlag', null);
    const adType = lodashGet(playbackDetails, 'adType', null);
    const isSkippable = lodashGet(playbackDetails, 'isSkippable', false);
    const skipTimeOffset = lodashGet(playbackDetails, 'skipTimeOffset', 0);
    const isAdsPaused = lodashGet(playbackDetails, 'isAdsPaused', false);
    const adsClickedLearnMoreFlag = lodashGet(playbackDetails, 'adsClickedLearnMoreFlag', false);
    return {
        playbackDetails,
        adsPlayingFlag,
        adType,
        isSkippable,
        skipTimeOffset,
        isAdsPaused,
        adsClickedLearnMoreFlag,
    };
};

export default withDeviceUtil(connect(mapStateToProps, {
    dispatchToggleFullScreen: toggleFullScreen,
})(withStyles(styles)(AdControlBar)));
