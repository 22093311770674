import lodashGet from 'lodash/get';
import { matchRoutes } from 'react-router';
import { LOCAL_STORE_KEYS, PROGRAM_TYPES } from '@airtel-tv/constants/GlobalConst';
import {
    goLiveEvent,
    playerFullScreenEvent,
    exitFullScreenEvent,
    playerForwardEvent,
    playerRewindEvent,
    pipOpenClickEvent,
    pipClosedClickEvent,
    apiInitEvent,
    apiResponseEvent,
    playerSeekEvent,
    clickEvent,
    playbackErrorEvent,
    playerInitEventV2,
    playerReadyToPlay,
    playStartEvent,
    playerLogEvent,
    pauseEvent,
    resumeEvent,
    playEndEvent,
    adsSDKEvent,
} from '@airtel-tv/analytics/FunctionalEvents';

import { PLAYER_EVENTS_LIST } from '@airtel-tv/constants/PlayerConsts';
import {
    ANALYTICS_ACTIONS,
    PLAY_STATUS_FIRE_TIME,
    ANALYTICS_CATEGORY,
    PIP_ANALYTICS_ACTIONS,
    PLAYER_CLICK_EVENTS,
} from '@airtel-tv/constants';
import { checkFullScreen } from '@airtel-tv/utils/WindowUtil';
import { playStartGaEvent } from '@airtel-tv/utils/GaEvents';
import {
    subtractSmallFromBig,
    getContentType,
    getPlaySessionId,
    setPlaySessionId,
    getPortraitorLandscape,
    getDeviceResolution,
    markStitchKeyConsumed,
    readStitchId,
    getMatchedRoute,
} from '@airtel-tv/utils/GlobalUtil';
import { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import { LocationUtil } from '@airtel-tv/utils';
import ROUTE_SOURCE_NAMES from '@airtel-tv/constants/RouteConsts';
import { licenseCrash, playbackError } from '@airtel-tv/crash-capture/CrashEvents';
import { ANALYTICS_ERROR_CODES, ANALYTICS_V2_ERROR_TYPES, ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import getAllRouters from '../../../web/src/routes/routes'; // Unify this

import ApiAnalytics from './ApiAnalytics';
import { AD_SOURCES, AD_TAG_TYPE } from '@airtel-tv/utils/AdSdkUtil';
import PlayerAnalyticsQueue from '../util/AnalyticsQueueBridge';

const routes = getAllRouters();

export const getUrlSourceName = (pathList, props) => {
    const browserHistoryLastPage = pathList[pathList.length - 2] || pathList[pathList.length - 1];
    let sourceUrl = LocationUtil.getUrlFromProps(props);

    if (browserHistoryLastPage) {
        sourceUrl = browserHistoryLastPage.pathname + browserHistoryLastPage.search;
    }

    let sourceName = ROUTE_SOURCE_NAMES.PLAYBACK;
    const routePresent = matchRoutes(routes, sourceUrl);
    if (routePresent.length > 1) {
        sourceName = ROUTE_SOURCE_NAMES[routePresent[1].route.path];
    }

    return sourceName;
};

export default class AnalyticsHelperV2 {
    constructor({
        playerEvents,
        contentDetails,
        playbackDetails,
        playbackCallInitTimeStamp,
        playerFunctions,
        sourceName,
        playbackSessionId,
        lang,
        isTrailerAuto = false,
        isContentAutoplay = false,
    }) {
        this.playerEvents = playerEvents;
        this.contentDetails = contentDetails;
        this.playbackDetails = playbackDetails;
        this.playbackCallInitTimeStamp = playbackCallInitTimeStamp;
        this.playerFunctions = playerFunctions;
        this.sourceName = sourceName;
        this.playbackSessionId = playbackSessionId;
        this.lang = lang;
        this.is_trailer_auto = isTrailerAuto;
        this.content_autoplay = isContentAutoplay;
        this.contentType = getContentType({ contentDetails: this.contentDetails });
        this.playInitHandler = this.playInitHandler.bind(this);
        this.stitchId = readStitchId();
        markStitchKeyConsumed();
        this.playerAnalyticsQueue = PlayerAnalyticsQueue;
        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.LOADSTART, () => this.playInitHandler());

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.LOADED, (videotag) => {
            playStartGaEvent(this.contentDetails.id);
            if (!this.playbackInitTime) {
                this.playbackInitTime = new Date().getTime();
            }
            this.firstBitratePicked = this.playerFunctions.getBandwidth();
            this.currentBitrate = this.firstBitratePicked;
            this.pushNewBitrateHistory();
            this.initBufferTime = this.totalWatlingTime;
            let { eventMeta, playbackMeta } = this.getMeta(true);
            playbackMeta = {
                ...playbackMeta,
                join_time: `${new Date().getTime() - this.playbackInitTime}`,
                ...(this.firstBitratePicked && { initial_bitrate: `${Math.floor(this.firstBitratePicked / 1000)}` }), // kbps
            };
            // playDuration
            this.playerAnalyticsQueue.pushEvent(() => playerReadyToPlay(eventMeta, playbackMeta));
            // check bitrate should be 0
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.FIRST_PLAY, () => {
            const trackParams = this.playerFunctions.getTrackParams();
            if (!this.playbackInitTime) {
                this.playbackInitTime = new Date().getTime();
            }
            this.playStartTime = new Date().getTime() - this.playbackInitTime;
            let { eventMeta, playbackMeta } = this.getMeta(true);
            this.getRepresentationData();
            playbackMeta = {
                ...playbackMeta,
                ...(trackParams.track_resolution && { track_resolution: trackParams.track_resolution }),
                play_start_time: `${this.playStartTime}`,
                play_duration: `${this.playDuration * 1000}`,
                ...(this.firstBitratePicked && { initial_bitrate: `${Math.floor(this.firstBitratePicked / 1000)}` }),
                ...(this.availableTrackMeta.available_bitrates && this.availableTrackMeta.available_bitrates.length && { available_bitrates: `[${this.availableTrackMeta.available_bitrates}]` }),
                ...(this.availableTrackMeta.available_resolutions && this.availableTrackMeta.available_resolutions.length && { available_resolutions: `[${this.availableTrackMeta.available_resolutions}]` }),
            };
            const selectedSubtitle = this.playerFunctions?.getSelectedSubtitle();
            if (selectedSubtitle) {
                eventMeta = {
                    ...eventMeta,
                    selected_subtitles: selectedSubtitle,
                };
            }
            this.playerAnalyticsQueue.pushEvent(() => {
                const adDuration = sessionStore.get(LOCAL_STORE_KEYS.IMA_AD_DURATION) || 0;
                if (adDuration) {
                    playbackMeta = {
                        ...playbackMeta,
                        perceived_click_to_play: `${(+playbackMeta.play_start_time) + (+adDuration) * 1000}`,
                    };
                    playStartEvent(eventMeta, playbackMeta);
                    return;
                }
                playStartEvent(eventMeta, playbackMeta);
            });
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.TIMEUPDATE, (currentTime, videotag) => {
            // current time in seconds
            const curTempTime = Math.floor(currentTime);
            // update oscillation change
            this.oscillationsChangeCount += this.getOscillationsChangeCount();
            // event is fired every .25 second so check if it is a new second
            if (curTempTime !== this.previousCurTime) {
                this.previousCurTime = curTempTime;

                // increment playback time
                this.continuousPlaybackCount += 1;
                this.playDuration += 1;

                if (this.continuousPlaybackCount % this.statusEventSyncInterval === 0) {
                    this.pushNewBitrateHistory();
                    // in case not bitrate change, and to add last bitrate duration
                    // if (this.lastDurationAddedForBitrateHistory !== this.statusEventSyncInterval) {
                    //     debugger;
                    //     this.pushNewBitrateHistory();
                    // }


                    let { eventMeta, playbackMeta } = this.getMeta();
                    const computedStats = this.getPlayerComputedStats();
                    playbackMeta = {
                        ...playbackMeta,
                        ...computedStats,
                    };
                    this.playerAnalyticsQueue.pushEvent(() => playerLogEvent(eventMeta, playbackMeta));
                    this.resetPlayStatusEventVariables();
                }
            }
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.PAUSE, (currentTime, videotag) => {
            if (this.seekBlock) {
                return;
            }
            let { eventMeta, playbackMeta } = this.getMeta();
            const computedStats = this.getPlayerComputedStats();
            this.playerPausedEventFired = true;
            playbackMeta = {
                ...playbackMeta,
                ...computedStats,
                pause_reason: 'user_action',
            };
            this.playerAnalyticsQueue.pushEvent(() => pauseEvent(eventMeta, playbackMeta));
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.PLAY, (currentTime, videotag) => {
            if (this.seekBlock) {
                this.seekBlock = false;
            }
            // playGaEvent(contentDetails.id);
            if (this.playerPausedEventFired) {
                let { eventMeta, playbackMeta } = this.getMeta();
                const computedStats = this.getPlayerComputedStats();
                this.playerPausedEventFired = true;
                playbackMeta = {
                    ...playbackMeta,
                    ...computedStats,
                };

                const selectedSubtitle = this.playerFunctions?.getSelectedSubtitle();
                if (selectedSubtitle) {
                    eventMeta = {
                        ...eventMeta,
                        selected_subtitles: selectedSubtitle,
                    };
                }
                this.playerAnalyticsQueue.pushEvent(() => resumeEvent(eventMeta, playbackMeta));
                this.playerPausedEventFired = false;
            }
        });
        // xsdss
        this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.WAITING_V2, () => {
            this.isWaiting = true;
            this.bufferingStatusChange(true);
        });

        this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH_V2, () => {
            this.bufferingStatusChange(false);
            this.isWaiting = false;
        });

        this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.WAITING, () => {
            // this.bufferingStatusChange(true);
        });

        this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.CAN_PLAY_THROUGH, () => {
            if (!this.isWaiting && this.seekStartTS) {
                this.seekStartTS = 0;
            }
            // this.bufferingStatusChange(false);
        });

        this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.ENDEND, (videotag) => {
            // The ended event is fired when playback or streaming has stopped because the end of the media was reached or because no further data is available.
            this.contentCompleted = true;
            this.oscillationsChangeCount += this.getOscillationsChangeCount();// @NGTD: Check if needed
            let { eventMeta, playbackMeta } = this.getMeta();
            const computedStats = this.getPlayerComputedStats();
            if (!this.playbackInitTime) {
                this.playbackInitTime = new Date().getTime();
            }
            let ebvs_time;
            if (!this.playStartTime) {
                ebvs_time = `${new Date().getTime() - this.playbackInitTime}`;
            }
            playbackMeta = {
                ...playbackMeta,
                ...computedStats,
                ...(ebvs_time && { ebvs_time }),
            };
            const { updatedEventMeta, updatedPlaybackMeta } = this.validateUserSessionId(eventMeta, playbackMeta);
            this.playerAnalyticsQueue.pushEvent(() => playEndEvent(updatedEventMeta, updatedPlaybackMeta));
        });

        this.playerEvents.emitter.on(PLAYER_EVENTS_LIST.DISPOSE, (videotag) => {
            if (this.contentCompleted) {
                return;
            }
            if (!this.playbackInitTime) {
                this.playbackInitTime = new Date().getTime();
            }
            let ebvs_time;
            if (!this.playStartTime) {
                ebvs_time = `${new Date().getTime() - this.playbackInitTime}`;
            }
            this.oscillationsChangeCount += this.getOscillationsChangeCount();// @NGTD: Check if needed
            let { eventMeta, playbackMeta } = this.getMeta();
            const computedStats = this.getPlayerComputedStats();
            playbackMeta = {
                ...playbackMeta,
                ...computedStats,
                ...(ebvs_time && { ebvs_time }),
            };
            const { updatedEventMeta, updatedPlaybackMeta } = this.validateUserSessionId(eventMeta, playbackMeta);
            this.playerAnalyticsQueue.pushEvent(() => playEndEvent(updatedEventMeta, updatedPlaybackMeta));
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.PLAYER_SEEK, (data) => {
            // debounced
            this.logSeek();
        });


        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.SEEK_BAR_CHANGE, (videotag) => {
            this.onSeekDataCapturing();
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.SEEK_PAUSE, (videotag) => {
            this.seekBlock = true;
        });

        playerEvents.emitter.on(PLAYER_EVENTS_LIST.ERROR, (error) => {
            const { eventMeta, playbackMeta } = this.getErrorMeta(error);
            let { userAgent, platform } = window.navigator;
            platform = LocationUtil.parseNavigatorForWindows(userAgent, platform);
            const { updatedEventMeta, updatedPlaybackMeta } = this.validateUserSessionId(eventMeta, playbackMeta);
            this.playerAnalyticsQueue.pushEvent(() => playbackErrorEvent(updatedEventMeta, updatedPlaybackMeta));
            this.updatePlaySessionIdOnError();
            this.playerAnalyticsQueue.pushEvent(() => playbackError({
                error_code: error && error.code ? error.code : '',
                errorObj: error,
                play_session_id: this.playbackSessionId,
                content_id: this.contentDetails.id,
                cp_name: this.contentDetails.cpId,
                userAgent,
                platform, // @NGTD: unify platform code
                // ...analytics,
            }));
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.LICENSE_API_INIT, (data) => {
            const license = lodashGet(this.playbackDetails, 'drm.url', null);
            const apiAnalytics = new ApiAnalytics({ contentId: this.contentDetails.id }, {
                url: license,
                category: ANALYTICS_CATEGORY.LICENSE,
            });
            const eventMeta = apiAnalytics.getEventMeta();
            let playbackMeta = apiAnalytics.getCorePlaybackMeta();
            const analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META);
            const { analyticsPlaySessionId } = analyticsMeta || {};
            playbackMeta = {
                play_session_id: analyticsPlaySessionId,
                ...playbackMeta,
            };
            apiInitEvent(eventMeta, playbackMeta);
        });


        // playerEvents.emitter.on(PLAYER_EVENTS_LIST.MANIFEST_API_RESPONSE, (data) => {
        //     const playUrl = lodashGet(playbackDetails, 'playback.playUrl', null);
        //     const apiAnalytics = new ApiAnalytics({contentId: contentDetails.id}, {
        //         url: playUrl,
        //         category: ANALYTICS_CATEGORY.MANIFEST
        //     });
        //     let eventMeta = apiAnalytics.getEventMeta();
        //     let playbackMeta = apiAnalytics.getCorePlaybackMeta();
        //     const analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META);
        //     const { analyticsPlaySessionId } = analyticsMeta;
        //     playbackMeta = {
        //         play_session_id: analyticsPlaySessionId,
        //         api_response_time: playerFunctions.manifestDownloadTime,
        //         ...playbackMeta
        //     }
        //     apiResponseEvent(eventMeta, playbackMeta);
        // });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.LICENSE_API_RESPONSE, (data) => {
            const license = lodashGet(this.playbackDetails, 'drm.url', null);
            const apiAnalytics = new ApiAnalytics({ contentId: this.contentDetails.id }, {
                url: license,
                category: ANALYTICS_CATEGORY.LICENSE,
            });
            const eventMeta = apiAnalytics.getEventMeta();
            let playbackMeta = apiAnalytics.getCorePlaybackMeta();
            const analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META);
            const { analyticsPlaySessionId } = analyticsMeta || {};
            playbackMeta = {
                play_session_id: analyticsPlaySessionId,
                api_status: 'success',
                ...(this.playerFunctions.licenseDownloadTime && { api_response_time: this.playerFunctions.licenseDownloadTime }),
                ...playbackMeta,
            };
            apiResponseEvent(eventMeta, playbackMeta);
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.LIVE_BUTTON_CLICK, (data) => {
            goLiveEvent({
                content_id: this.contentDetails.id,
                source_name: this.sourceName,
                live_position: data,
                action: ANALYTICS_ACTIONS.LIVE_BUTTON_CLICK,
            });
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.SEEK_FORWARD, (data) => {
            playerForwardEvent({
                fullscreen: !!checkFullScreen(),
                content_id: this.contentDetails.id,
                source_name: this.sourceName,
                action: ANALYTICS_ACTIONS.SEEK_FORWARD,
                current_position: data,
                play_session_id: this.playbackSessionId,
            });
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.SEEK_BACKWARD, (data) => {
            playerRewindEvent({
                fullscreen: data.fullscreen,
                content_id: this.contentDetails.id,
                source_name: this.sourceName,
                action: ANALYTICS_ACTIONS.SEEK_BACKWARD,
                current_position: data,
                play_session_id: data.play_session_id,
            });
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.FULL_SCREEN_CHANGE, () => {
            const isFullScreen = !!checkFullScreen();
            const meta = {
                content_id: this.contentDetails.id,
                source_name: this.sourceName,
                action: isFullScreen
                    ? ANALYTICS_ACTIONS.FULL_SCREEN
                    : ANALYTICS_ACTIONS.EXIT_FULL_SCREEN,
            };

            // fullScreenToggleGaEvent(contentDetails.id);

            if (isFullScreen) {
                playerFullScreenEvent(meta);
            }
            else {
                exitFullScreenEvent(meta);
            }

            let clickMeta = this.getClickMeta();
            clickMeta = {
                action: isFullScreen ? PLAYER_CLICK_EVENTS.FULL_SCREEN : PLAYER_CLICK_EVENTS.HALF_SCREEN,
                ...clickMeta,
            };
            clickEvent(clickMeta);
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.OPEN_PIP, () => {
            const meta = {
                content_id: this.contentDetails.id,
                cpName: this.contentDetails.cpId,
                content_type: this.contentType,
            };
            pipOpenClickEvent(meta);

            let clickMeta = this.getClickMeta();
            clickMeta = {
                action: PIP_ANALYTICS_ACTIONS.PLAY,
                ...clickMeta,
            };
            clickEvent(clickMeta);
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.CLOSE_PIP, () => {
            const meta = {
                content_id: this.contentDetails.id,
                cpName: this.contentDetails.cpId,
                content_type: this.contentType,
            };
            pipClosedClickEvent(meta);

            let clickMeta = this.getClickMeta();
            clickMeta = {
                action: PIP_ANALYTICS_ACTIONS.PIP_OUT,
                ...clickMeta,
            };
            clickEvent(clickMeta);
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.MUTE_V2, () => {
            let clickMeta = this.getClickMeta();
            clickMeta = {
                action: PLAYER_CLICK_EVENTS.MUTE,
                ...clickMeta,
            };
            clickEvent(clickMeta);
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.NEXT_EPISODE_CLICK, () => {
            let clickMeta = this.getClickMeta();
            clickMeta = {
                action: PLAYER_CLICK_EVENTS.NEXT_EPISODE,
                ...clickMeta,
            };
            clickEvent(clickMeta);
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.UNMUTE_V2, () => {
            let clickMeta = this.getClickMeta();
            clickMeta = {
                action: PLAYER_CLICK_EVENTS.UNMUTE,
                ...clickMeta,
            };
            clickEvent(clickMeta);
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.CHANGE_BIT_RATE_V2, (payload) => {
            let clickMeta = this.getClickMeta();
            clickMeta = {
                action: PLAYER_CLICK_EVENTS.PLAYBACK_SETTINGS,
                asset_name: payload.data,
                quality: 'true',
                audio_language: 'false',
                subtitles: 'false',
                ...clickMeta,
            };
            clickEvent(clickMeta);
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.CHANGE_AUDIO_LANGUAGE, (payload) => {
            let clickMeta = this.getClickMeta();
            clickMeta = {
                action: PLAYER_CLICK_EVENTS.PLAYBACK_SETTINGS,
                asset_name: payload.data,
                quality: 'false',
                audio_language: 'true',
                subtitles: 'false',
                ...clickMeta,
            };
            clickEvent(clickMeta);
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.CHANGE_SUBTITLES, (payload) => {
            let clickMeta = this.getClickMeta();
            clickMeta = {
                action: PLAYER_CLICK_EVENTS.PLAYBACK_SETTINGS,
                asset_name: payload.data,
                quality: 'false',
                audio_language: 'false',
                subtitles: 'true',
                ...clickMeta,
            };
            clickEvent(clickMeta);
        });

        this.playerEvents?.emitter?.on(PLAYER_EVENTS_LIST.ADS_SDK_EVENT, (payload) => {
            const { eventType, playback_session_id, web_sdk_version, event_name, skip_offset, ad_error_reason, error_reason, isPartnerBased, ...restData } = payload || {};
            const resolutionInfo = getDeviceResolution();
            const playbackMeta = {
                play_session_id: playback_session_id,
                parent_play_session_id: this.playSessionId,
            };
            const errorReason = ad_error_reason || error_reason;

            const meta = {
                ...restData,
                ...(errorReason && { error_reason: errorReason }),
                user_session_id: this.userSessionId,
                player_name: this.playerFunctions?.playerType,
                playback_stitch_key: this.stitchId,
                device_resolution: resolutionInfo?.resolution,
                player_version: this.playerFunctions?.playerVersion,
                is_trailer_auto: this.is_trailer_auto,
                playback_funnel_v2_enabled: true,
                client_ad_source: isPartnerBased ? AD_SOURCES.PARTNER : AD_SOURCES.AIRTEL, //@NGTD: Change it when sony ads are powered by sdk
                client_ad_type: isPartnerBased ? AD_TAG_TYPE.VMAP : AD_TAG_TYPE.VAST,
            };

            adsSDKEvent({ meta, playbackMeta }, event_name);

            if (event_name === 'internal_ad_error') {
                adsSDKEvent({ meta, playbackMeta }, 'ad_error');
            }
        });
    }

    contentCompleted = false;

    statusEventSyncInterval = PLAY_STATUS_FIRE_TIME;

    playbackLoadedTimeStamp = new Date().getTime();

    playbackIndexDomain = '';

    streamingApiResponseTime = null;

    initBufferTime = 0;

    firstBitratePicked = 0;

    clickToPlay = 0;

    // PLAY STATUS VAR DECLARATIONS
    playerPausedEventFired = false;

    // used for time update
    previousCurTime = 0;

    playDuration = 0;

    continuousPlaybackCount = 0;

    playStartTime = null;

    // used for buffering
    watlingInitTimeStamp = false;

    totalWatlingTime = 0;

    // used for oscillations
    oscillationsChangeCount = 0;

    previousBitrateIndex = 0;

    // used for seeks
    totalSeekCount = 0;

    totalSeekFirstFrameTime = 0;

    seekStartTS = 0;

    totalBitrateUsedOnSeek = 0;

    // bitrate history
    bitrateHistory = [];

    currentBitrate = 0;

    lastDurationAddedForBitrateHistory = 0;

    playbackInitTime;

    waitingCount = 0;

    rebufferCountOnSeek = 0;

    seekBlock;

    availableTrackMeta = {};

    totalBitrateConsumed = 0;

    playInitHandler() {
        const drmUrl = lodashGet(this.playbackDetails, 'drm.url', null);
        const playSessionId = getPlaySessionId(); // play session id gets generated at time of play_init
        setPlaySessionId(playSessionId);
        this.playSessionId = playSessionId;
        this.playInitCalled = true;
        let { eventMeta, playbackMeta } = this.getMeta();
        playbackMeta = {
            ...playbackMeta,
            ...(drmUrl && { license_url: drmUrl }),
        };
        this.playerAnalyticsQueue.pushEvent(() => playerInitEventV2(eventMeta, playbackMeta));
        this.playbackInitTime = new Date().getTime();
    }

    resetPlayStatusEventVariables() {
        this.watlingInitTimeStamp = false;
        // this.previousBitrateIndex = 0;
        this.seekStartTS = 0;
        this.continuousPlaybackCount = 0;
        this.currentBitrate = this.playerFunctions.getBandwidth();
    }

    updatePlaySessionIdOnError() {
        // On occurence of error we would like to caputure user retry attempts hence updating
        // user session id on every playback error
        const playSessionId = getPlaySessionId();
        setPlaySessionId(playSessionId);
        this.playSessionId = playSessionId;
    }

    calculateAverageBitrate() {
        if (!this.playDuration) {
            return 0;
        }
        const bitrate = this.totalBitrateConsumed ? this.totalBitrateConsumed : this.playerFunctions.getBandwidth();
        const time = this.lastDurationAddedForBitrateHistory ? this.lastDurationAddedForBitrateHistory : this.playDuration;
        this.currentBitrate = bitrate;
        return bitrate / time;
    }

    // pushNewBitrateHistory() {
    //     this.bitrateHistory.push({
    //         bitrate: this.currentBitrate,
    //         duration: this.continuousPlaybackCount - this.lastDurationAddedForBitrateHistory,
    //         count: this.playDuration,
    //     });
    //     this.lastDurationAddedForBitrateHistory = this.continuousPlaybackCount;
    //     this.currentBitrate = this.playerFunctions.getBandwidth();
    //     console.log(this.bitrateHistory);
    // };

    pushNewBitrateHistory() {
        if (!this.playDuration) {
            this.totalBitrateConsumed = 0;
        }
        else {
            this.currentBitrate = this.playerFunctions.getBandwidth();
            const diff = this.playDuration - this.lastDurationAddedForBitrateHistory;
            this.totalBitrateConsumed += (this.currentBitrate * diff);
            this.lastDurationAddedForBitrateHistory = this.playDuration;
            console.log('totalBitrate===> ', this.totalBitrateConsumed);
        }
    }

    getOscillationsChangeCount() {
        let changeCount = 0;
        const bitrateIndex = this.playerFunctions.getCurrentRepresentationIndex();
        if (bitrateIndex !== this.previousBitrateIndex) {
            changeCount += subtractSmallFromBig(
                this.previousBitrateIndex,
                bitrateIndex,
            );
            this.previousBitrateIndex = bitrateIndex;

            this.pushNewBitrateHistory();
        }
        return changeCount;
    }

    bufferingStatusChange(isBuffering) {
        if (isBuffering === true) {
            this.watlingInitTimeStamp = new Date().getTime();
            this.waitingCount++; // Check on initial
            if (this.seekStartTS) {
                this.rebufferCountOnSeek++;
            }
            // console.log("klog", "buffering",this.watlingInitTimeStamp )
        }
        else {
            // console.log("klog2", this.watlingInitTimeStamp);
            let diff;
            if (this.watlingInitTimeStamp) {
                diff = new Date().getTime() - this.watlingInitTimeStamp;
                this.totalWatlingTime += diff;
                this.watlingInitTimeStamp = false;
            }
            if (this.seekStartTS !== 0) {
                this.totalBitrateUsedOnSeek += this.playerFunctions.getBandwidth();
                // this.rebufferCountOnSeek++;
                if (!diff) {
                    diff = new Date().getTime() - this.seekStartTS;
                    this.totalWatlingTime += diff;
                }
                this.totalSeekFirstFrameTime += diff;
                this.seekStartTS = 0;
            }
            // console.log("klog3",this.totalWatlingTime, this.totalSeekFirstFrameTime);
        }
    }

    logSeek() {
        this.oscillationsChangeCount += this.getOscillationsChangeCount();
        let { eventMeta, playbackMeta } = this.getMeta();
        const computedStats = this.getPlayerComputedStats();
        playbackMeta = {
            ...playbackMeta,
            ...computedStats,
        };
        playerSeekEvent(eventMeta, playbackMeta); // NGTD: verify values
    }

    onSeekDataCapturing() {
        if (this.seekStartTS === 0) {
            this.seekStartTS = new Date().getTime();
            this.totalSeekCount += 1;
        }
    }


    getRepresentationData() {
        const trackMeta = this.playerFunctions.getRepresentationMeta();
        this.availableTrackMeta = {
            available_resolutions: [],
            available_bitrates: [],
        };
        trackMeta.map((item) => {
            item.track_resolution && this.availableTrackMeta.available_resolutions.push(item.track_resolution),
                item.bandwidth && this.availableTrackMeta.available_bitrates.push((item.bandwidth / 1000).toFixed(2));
        });
    }

    getClickMeta() {
        const clickMeta = {
            seek_time: `${this.totalSeekFirstFrameTime}`,
            content_id: this.contentDetails.id,
            content_type: this.contentDetails.programType,
            stream_mode_online: 'true',
            fullscreen: `${!!checkFullScreen()}`,
            user_player_mute_status: `${this.playerFunctions.isMute()}`,
            user_session_id: this.userSessionId,
            play_session_id: this.playSessionId,
        };
        return clickMeta;
    }

    validateUserSessionId(eventMetaParam, playbackMetaParam) {
        const eventMeta = { ...eventMetaParam };
        const playbackMeta = { ...playbackMetaParam };
        if (eventMeta.user_session_id && playbackMeta.play_session_id) {
            let playbackAssociatedUserSessionId = playbackMeta.play_session_id.split('-');
            playbackAssociatedUserSessionId = `${playbackAssociatedUserSessionId[0]}-${playbackAssociatedUserSessionId[1]}`;
            if (eventMeta.user_session_id !== playbackAssociatedUserSessionId) {
                eventMeta.user_session_id = playbackAssociatedUserSessionId;
            }
        }
        return {
            updatedEventMeta: eventMeta,
            updatedPlaybackMeta: playbackMeta,
        };
    };


    getCorePlaybackMeta() {
        const playUrl = lodashGet(this.playbackDetails, 'playback.playUrl', null);
        const urlSchema = LocationUtil.parseUrl(playUrl);
        if (!this.playSessionId) {
            let analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META);
            const { analyticsPlaySessionId } = analyticsMeta || {};
            if (analyticsPlaySessionId && this.playInitCalled) { // playInitCalled in case end > trailer > end before play init
                this.playSessionId = analyticsPlaySessionId;
            }
            else {
                this.playInitHandler();
                analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META);
                this.playSessionId = analyticsMeta.analyticsPlaySessionId;
            }
        }
        // console.log('zzz', this.playSessionId);
        return {
            video_url: playUrl, // @NGTD: MOVE TO PLAYBAK INIT
            playback_host: urlSchema.hostname, // @NGTD: MOVE TO PLAYBAK INIT
            play_session_id: this.playSessionId,
            is_drm_content: this.playbackDetails.drm ? 'true' : 'false',
        };
    }

    getEventMeta(playerEvent) { // remove playerEvent requirement
        let obj = {
            content_id: this.playbackDetails?.contentType === PROGRAM_TYPES.TRAILER ? this.playbackDetails?.contentId : this.contentDetails?.id,
            content_category: 'Content', // No ad flow present
            playback_funnel_v2_enabled: 'true',
            is_trailer_auto: this.is_trailer_auto,
            content_autoplay: this.content_autoplay,
        };
        if (playerEvent) {
            const analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META) || {};
            const { userSessionId = '' } = analyticsMeta;
            this.userSessionId = userSessionId;
            const resolutionInfo = getDeviceResolution();
            obj = {
                ...obj,
                player_name: this.playerFunctions.playerType,
                stream_mode_online: 'true',
                user_session_id: this.userSessionId,
                orientation: getPortraitorLandscape(),
                device_resolution: resolutionInfo.resolution,
                player_version: this.playerFunctions.playerVersion,
                ...(this.stitchId && { playback_stitch_key: this.stitchId }),
                // bw
            };
        }

        return obj;
    }


    getMeta(appendPlayerStats) {
        let eventMeta = this.getEventMeta(true);
        let playbackMeta = this.getCorePlaybackMeta();
        const stats = this.playerFunctions.getPlayerStats();
        eventMeta = {
            ...eventMeta,
            ...(stats.networkBandwidth && { network_bandwidth: `${Math.floor(stats.networkBandwidth)}` }),
        };
        if (appendPlayerStats) {
            playbackMeta = {
                ...playbackMeta,
                ...(stats.licenseTime && { license_download_time: `${stats.licenseTime}` }),
                ...(stats.manifestDownloadTime && { index_file_download_time: `${stats.manifestDownloadTime}` }),
                // loadLatency: `${stats.loadLatency}`,
            };
        }
        return {
            eventMeta,
            playbackMeta,
        };
    }

    getPlayerComputedStats() {
        const playingBitrate = this.playerFunctions.getBandwidth();
        const trackParams = this.playerFunctions.getTrackParams();
        const currentSeekPosition = this.playerFunctions.getCurrentTime();
        // const totalPlayTime = new Date().getTime() - this.playbackInitTime; //Includes pause time as well
        const play_duration = this.playDuration * 1000;
        const average_bitrate = this.calculateAverageBitrate();
        let rebuffer_percentage;
        if (play_duration) {
            rebuffer_percentage = ((this.totalWatlingTime / (this.totalWatlingTime + play_duration)) * 100);
            rebuffer_percentage = rebuffer_percentage ? rebuffer_percentage.toFixed(2) : rebuffer_percentage;
        }
        return {
            rebuffer_time: `${this.totalWatlingTime}`, // ms
            rebuffer_count: `${this.waitingCount}`,
            ...(rebuffer_percentage && { rebuffer_percentage }),
            // rebuffer_percentage: `${((this.totalWatlingTime / (this.totalWatlingTime + play_duration)) * 100).toFixed(2)}`, //NGTD: r/r+p; check p's value
            // rebuffer_percentage: (this.totalWatlingTime / (this.totalWatlingTime + (this.playDuration * 1000)) * 100), //NGTD: r/r+p; check p's value
            ...(this.oscillationsChangeCount && { bitrate_oscillations: `${this.oscillationsChangeCount}` }),
            seek_count: `${this.totalSeekCount}`,
            rebuffer_time_on_seek: `${this.totalSeekFirstFrameTime}`,
            rebuffer_count_on_seek: `${this.rebufferCountOnSeek}`,
            // average_click_to_play_on_seek: totalSeekCount ? totalSeekFirstFrameTime / 1000 / totalSeekCount: 0,
            ...(this.totalBitrateUsedOnSeek && this.totalSeekCount && { average_bitrate_on_seek: `${(this.totalBitrateUsedOnSeek / this.totalSeekCount) / 1000}` }),
            ...(this.firstBitratePicked && { initial_bitrate: `${Math.floor(this.firstBitratePicked / 1000)}` }), // Kbps
            ...(average_bitrate && { average_bitrate: `${Math.floor(average_bitrate / 1000)}` }), // kbps
            ...(playingBitrate && { current_bitrate: `${Math.floor(playingBitrate / 1000)}` }), // kbps
            // play_duration: `${new Date().getTime() - this.playbackInitTime}`,//can it be improved by using play duration in player stats, player pause
            play_duration: `${this.playDuration * 1000}`,
            ...(this.initBufferTime && { initial_buffer_time: `${Math.floor(this.initBufferTime * 1000)}` }), // ms
            ...(this.availableTrackMeta.available_bitrates && this.availableTrackMeta.available_bitrates.length && { available_bitrates: `[${this.availableTrackMeta.available_bitrates}]` }),
            ...(this.availableTrackMeta.available_resolutions && this.availableTrackMeta.available_resolutions.length && { available_resolutions: `[${this.availableTrackMeta.available_resolutions}]` }),
            ...(trackParams.track_resolution && { track_resolution: `${trackParams.track_resolution}` }),
            ...(trackParams.frame_rate && { frame_rate: `${trackParams.frame_rate}` }),
            current_seek_position: `${Math.floor(currentSeekPosition)}`,

        };
    }

    handleHeartBeatErrorAnalytics(error) {
        const { message, errorcode } = error || {};
        if (![
            ERROR_CODES.ATV208,
            ERROR_CODES.ATV207,
        ].includes(errorcode)) {
            return;
        }
        let { eventMeta, playbackMeta } = this.getErrorMeta(error);

        playbackMeta = {
            ...playbackMeta,
            ...(message && { error_message: message }),
            error_code: ERROR_CODES.ATV_CL_835,
            error_type: ANALYTICS_V2_ERROR_TYPES.SYSTEM_ERROR,
            is_recoverable: false,
            error_reason: JSON.stringify(error),
        };
        playbackErrorEvent(eventMeta, playbackMeta);
        this.updatePlaySessionIdOnError();
    }

    errorParser(error) {
        const { code } = error;
        let responseHeaders; let
            responseText;

        let analyticsErrorMeta = ANALYTICS_ERROR_CODES[code];
        if (!code) {
            error.data = error?.srcElement?.error?.message;
        }

        if (code == ERROR_CODES.SHAKA_1001) {
            const { data } = error;
            if (Array.isArray(data)) {
                responseText = data[2] ? data[2] : null;
                responseHeaders = data[3] ? JSON.stringify(data[3]) : null;
            }
            analyticsErrorMeta.is_recoverable = false;
            if (error.is_recoverable) {
                analyticsErrorMeta.is_recoverable = true;
            }
        }

        if (!analyticsErrorMeta) {
            analyticsErrorMeta = {
                error_code: `${code || ERROR_CODES.ATV_CL_701}`, // undefined error code handling
                error_type: ANALYTICS_V2_ERROR_TYPES.SYSTEM_ERROR,
                is_recoverable: true,
                player_error_code: `${code || ERROR_CODES.ATV_CL_701}`, // addnl
                error_message: JSON.stringify(error && error.data), // addnl
            };
        }
        let { userAgent, platform } = window.navigator;
        platform = LocationUtil.parseNavigatorForWindows(userAgent, platform);
        const trackParams = this.playerFunctions.getTrackParams();
        const {
            track_resolution, video_codec, audio_codec, frame_rate, bandwidth,
        } = trackParams;
        analyticsErrorMeta = {
            ...analyticsErrorMeta,
            is_recoverable: analyticsErrorMeta.is_recoverable ? 'true' : 'false',
            error_stack_trace: error.stack || error.stackTrace || '',
            failure_reason: JSON.stringify(error),
            error_reason: JSON.stringify(error && error.data),
            ...(responseHeaders && { response_headers: responseHeaders }),
            ...(responseText && { response_body: responseText }),
            ...(this.firstBitratePicked && { initial_bitrate: `${Math.floor(this.firstBitratePicked / 1000)}` }),
            content_id: this.contentDetails.id,
            cp_name: this.contentDetails.cpId,
            userAgent,
            source: platform,
            ...(track_resolution && { track_resolution: `${track_resolution}` }),
            ...(video_codec && { video_codec: `${video_codec}` }),
            ...(audio_codec && { audio_codec: `${audio_codec}` }),
            ...(frame_rate && { frame_rate: `${frame_rate}` }),
            ...(bandwidth && { current_bitrate: `${bandwidth}` }),
        };
        return analyticsErrorMeta;
        // play duration
    }

    getCorePlaybackErrorMeta(error) {
        const playUrl = lodashGet(this.playbackDetails, 'playback.playUrl', null);
        const urlSchema = LocationUtil.parseUrl(playUrl);
        if (!this.playSessionId) {
            let analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META);
            const { analyticsPlaySessionId } = analyticsMeta || {};
            if (analyticsPlaySessionId && this.playInitCalled) {
                this.playSessionId = analyticsPlaySessionId;
            }
            else {
                this.playInitHandler();
                analyticsMeta = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_META);
                this.playSessionId = analyticsMeta?.analyticsPlaySessionId;
            }
        }
        const analyticsErrorMeta = this.errorParser(error);

        return {
            video_url: playUrl,
            playback_host: urlSchema.hostname,
            play_session_id: this.playSessionId,
            is_drm_content: this.playbackDetails.drm ? 'true' : 'false',
            ...analyticsErrorMeta,
        };
    }

    getErrorMeta(error) {
        if (!this.playbackInitTime) {
            this.playbackInitTime = new Date().getTime();
        }
        let eventMeta = this.getEventMeta(true);
        let playbackMeta = this.getCorePlaybackErrorMeta(error);
        const stats = this.playerFunctions.getPlayerStats();
        const isPlayStartError = !this.playStartTime;
        const requestId = lodashGet(this.playbackDetails, 'requestId', null);
        eventMeta = {
            ...eventMeta,
            ...(stats.networkBandwidth && { network_bandwidth: `${Math.floor(stats.networkBandwidth)}` }),
            ...(requestId && { playback_request_id: requestId }),
            is_play_start_error: `${isPlayStartError}`,
        };
        playbackMeta = {
            ...playbackMeta,
            ...(isPlayStartError && {
                ebvs_time: `${new Date().getTime() - this.playbackInitTime}`,
                play_duration: `${this.playDuration * 1000}`, // check value here
                current_seek_position: '0', // NGTD: get it from player
            }),
        };
        return {
            eventMeta,
            playbackMeta,
        };
    }

    getSyncData() {
        return {
            playSessionId: this.playSessionId,
            playDuration: this.playDuration,
        };
    }
}
