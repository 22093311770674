/* eslint-disable react/forbid-prop-types */
import lodashSet from 'lodash/set';
import lodashGet from 'lodash/get';
// import shaka from 'shaka-player-legacy';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { STREAM_TYPES } from '@airtel-tv/constants/BrowserConst';
import {
    PLAYER_CONFIG,
    PLAYER_TYPES,
    PLAYER_EVENTS_LIST,
    VIDEO_TAG_ID,
} from '@airtel-tv/constants/PlayerConsts';
import { ERROR_CODES } from '@airtel-tv/constants/ErrorCodes';
import { withDeviceUtil } from '@airtel-tv/utils/withDeviceUtil';
import { ANALYTICS_ASSETS } from '@airtel-tv/constants/AnalyticsConst';
import {
    adBreakEndEvent, adLoadEvent,
    adStartedEvent,
    adInitEvent,
    adEndedEvent,
    adErrorEvent,
} from '@airtel-tv/analytics/FunctionalEvents';
import { AD_TYPE, LOCAL_STORE_KEYS } from '@airtel-tv/constants/GlobalConst';
import { checkFullScreen, raiseEvent } from '@airtel-tv/utils/WindowUtil';
import { updateKeyboardControlsAction } from '@airtel-feature/notify/NotifyActions';
import { sessionStore } from '@airtel-tv/utils/BrowserStoreUtil';
import getPlayerControls from '../../factories/PlayerControlsFactory';
import getPlayerEvents from '../../factories/PlayerEventsFactory';
import { AbstractPlayer } from '../abstract-player/AbstractPlayer';
import {
    adsPlaying, setSdkAdsPlaying,
} from '../../actions/PlaybackActions';
import LanguageCodesProvider from '../../../../web/src/providers/LanguageCodesProvider';
import PlayerAnalyticsQueueInst, {PlayerAnalyticsQueue} from './../../util/AnalyticsQueueBridge';
import { isWeb as isWebBrowser } from '../../util/PlaybackUtility';

const LANGUAGES_CODES = LanguageCodesProvider();


class HlsPlayerComponentV2 extends AbstractPlayer {
    constructor(props) {
        super(props, PLAYER_TYPES.HLS_PLAYER);
        this.PlayerCreated = false;
        this.isImaAdPlaying = false;
        this.isWeb = isWebBrowser();
    }

    onStreamEvent = (e) => {
        const contentId = lodashGet(this.props?.playbackConfig, 'contentId', '');

        switch (e.type) {
            case google.ima.dai.api.StreamEvent.Type.LOADED:
                const { url } = e.getStreamData();
                this.hls.loadSource(url);
                this.hls.attachMedia(this.videoNode);
                this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
                    console.log('Video Play');
                    this.videoNode.play();
                });
                adInitEvent({
                    content_id: contentId,
                });
                break;
        }
    };

    onAdsManagerLoaded(adsManagerLoadedEvent) {
        // Instantiate the AdsManager from the adsLoader response and pass it the video element
        const {
            dispatchGetAdsPlaying, playbackConfig, playbackDetails, updateKeyboardControlsActionDispatch,
        } = this.props;
        let timerElement = window.document.getElementById('ad-timer');
        // alert("Hi")

        const { stitchId } = sessionStore.get(LOCAL_STORE_KEYS.ANALYTICS_STITCH_META) || {};
        const contentId = lodashGet(playbackConfig, 'contentId', '');
        const adsAnalyticsMeta = {
            content_id: contentId,
            playback_stitch_key: stitchId,
        };

        const imaAdsMeta = {
            ...adsAnalyticsMeta,
            ads_player_position: 'preroll_ads_before_play_init',
        };

        this.adsManager = adsManagerLoadedEvent.getAdsManager(this.videoNode);

        this.adsManager.addEventListener(
            google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
            () => {
                console.log('====> ads CONTENT_PAUSE_REQUESTED');

                this.videoNode.pause();
                this.adContainer.style['z-index'] = 999;
                console.log('====> ads CONTENT_PAUSE_REQUESTED zindex', 999);
            },
        );
        this.adsManager.addEventListener(
            google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
            () => {
                console.log('====> ads CONTENT_RESUME_REQUESTED');
                this.videoNode.play();
                if (checkFullScreen()) {
                    this.adContainer.style['z-index'] = 'auto';
                }
            },
        );

        this.adsManager.addEventListener(
            google.ima.AdEvent.Type.LOADED,
            () => {
                this.isImaAdPlaying = true;
                adInitEvent(imaAdsMeta);
            },
        );

        this.adsManager.addEventListener(
            google.ima.AdEvent.Type.STARTED,
            (e) => {
                const adData = e.getAd();
                this.setAdManager(this.adsManager);
                const adsPlayingFlag = true;
                const { CLIENT_SIDE_AD = 'CLIENT_SIDE_AD' } = AD_TYPE;
                this.isImaAdPlaying = true;
                if (checkFullScreen()) {
                    this.adContainer.style['z-index'] = 999;
                    console.log('====> ads STARTED zindex', 999);
                }
                else {
                    this.adContainer.style['z-index'] = 10;
                    console.log('====> ads STARTED zindex', 10);
                }
                dispatchGetAdsPlaying({
                    adsPlayingFlag,
                    adType: CLIENT_SIDE_AD,
                });
                updateKeyboardControlsActionDispatch({ enableKeyboardControls: false });
                adStartedEvent(imaAdsMeta);
                sessionStore.set(LOCAL_STORE_KEYS.IMA_AD_DURATION, adData.getDuration());
            },
        );

        this.adsManager.addEventListener(
            google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
            () => {
                const adsPlayingFlag = false;
                this.isImaAdPlaying = false;
                this.adContainer.style['z-index'] = 'auto';
                dispatchGetAdsPlaying({
                    adsPlayingFlag,
                    adType: '',
                });
                // console.log('====> all ads completed');
                updateKeyboardControlsActionDispatch({ enableKeyboardControls: true });
                PlayerAnalyticsQueueInst.flushAllEvents();
                PlayerAnalyticsQueue.stopAnalyticsEventsQueue();
                sessionStore.remove(LOCAL_STORE_KEYS.IMA_AD_DURATION);
            },
        );

        this.adsManager.addEventListener(
            google.ima.AdEvent.Type.COMPLETE,
            () => {
                const adsPlayingFlag = false;
                this.isImaAdPlaying = false;
                this.adContainer.style['z-index'] = 'auto';
                dispatchGetAdsPlaying({
                    adsPlayingFlag,
                    adType: '',
                });
                console.log('====> ads completed');
                updateKeyboardControlsActionDispatch({ enableKeyboardControls: true });
                adEndedEvent(imaAdsMeta);
                PlayerAnalyticsQueueInst.flushAllEvents();
                PlayerAnalyticsQueue.stopAnalyticsEventsQueue();
                sessionStore.remove(LOCAL_STORE_KEYS.IMA_AD_DURATION);
                console.log('I am running COMPLETE')
            },
        );

        this.adsManager.addEventListener(
            google.ima.AdEvent.Type.AD_ERROR,
            () => {
                this.isImaAdPlaying = false;
                this.adContainer.style['z-index'] = 'auto';
                adErrorEvent(imaAdsMeta);
                PlayerAnalyticsQueueInst.flushAllEvents();
                PlayerAnalyticsQueue.stopAnalyticsEventsQueue();
                sessionStore.remove(LOCAL_STORE_KEYS.IMA_AD_DURATION);
            },
        );

        this.adsManager.addEventListener(
            google.ima.AdEvent.Type.PAUSED,
            () => {
                this.adContainer.style['z-index'] = 10;
                raiseEvent(this.videoNode, PLAYER_EVENTS_LIST.AD_PAUSE);
            },
        );

        this.adsManager.addEventListener(
            google.ima.AdEvent.Type.RESUMED,
            () => {
                raiseEvent(this.videoNode, PLAYER_EVENTS_LIST.AD_RESUME);
            },
        );

        this.adsManager.addEventListener(
            google.ima.AdEvent.Type.AD_PROGRESS,
            (e) => {
                console.log(e.getAdData());
                console.log('====> ads progress');
                const progressData = e.getAdData();
                this.isImaAdPlaying = true;
                const {
                    duration, adPosition, totalAds, currentTime,
                } = progressData;
                const timeRemaining = Math.ceil(duration - currentTime);
                const min = Math.floor(timeRemaining / 60);
                const sec = timeRemaining % 60;
                if (checkFullScreen()) {
                    this.adContainer.style['z-index'] = 999;
                    console.log('====> ads progress zINdex', 999);
                }
                else {
                    this.adContainer.style['z-index'] = 10;
                    console.log('====> ads progress zINdex', 10);
                }
                if (!timerElement) {
                    timerElement = document.getElementById('ad-timer');
                }
                console.log('====> ads progress timerElement  ===> ', timerElement, `Ad ${adPosition} of ${totalAds} (${min}:${sec})`);
                timerElement && (timerElement.innerHTML = `Ad ${adPosition} of ${totalAds} (${min}:${sec})`);
            },
        );
    }


    loadAds(event) {
        // Prevent this function from running on if there are already ads loaded
        if (this.adsLoaded) {
            return;
        }
        this.adsLoaded = true;

        // Prevent triggering immediate playback when ads are loading
        event.preventDefault();

        console.log('loading ads');

        // this.videoNode.load();
        this.adDisplayContainer.initialize();


        const width = this.videoNode.clientWidth;
        const height = this.videoNode.clientHeight;
        try {
            this.adsManager.init(width, height, google.ima.ViewMode.NORMAL);
            this.adsManager.start();
        }
        catch (adError) {
            // alert("zzz");
            // Play the video without ads, if an error occurs
            console.log('AdsManager could not be started');
            this.videoNode.play();
        }
    }

    onAdError(adErrorEvent) {
        // Handle the error logging.
        console.log(adErrorEvent.getError());
        if (adsManager) {
            adsManager.destroy();
        }
    }

    async initPlayer(reload) {
        // destructors props to use locals
        console.log('init player fired =====>>>>>');
        const {
            playbackConfig,
            playbackConfig: { useLegacyShaka = false } = {},
            contentDetails,
            playbackDetails,
            dispatchGetAdsPlaying,
            bufferingGoal = PLAYER_CONFIG.GOAL_BUFFER_LENGTH,
            isTrailerTile = false,
        } = this.props;


        const {
            streamType,
            daiAssetKey,
        } = playbackConfig || {};

        // if no dia asset key then player is returning
        if (!daiAssetKey) {
            return;
        }

        const timerElement = window.document.getElementById('ad-timer');

        const contentName = lodashGet(playbackConfig, 'videoMeta.videoTitle', '');
        const isdrm = lodashGet(playbackConfig, 'isDrm', '');
        const contentId = lodashGet(playbackConfig, 'contentId', '');
        const cpName = lodashGet(playbackDetails, 'cp', '');
        const contentLanguage = lodashGet(playbackDetails, 'plg', '');
        const playbackContentType = lodashGet(playbackDetails, 'playbackType', '');
        const adTagUrl = lodashGet(playbackDetails, 'ads.meta.url', '');
        const adsAnalyticsMeta = {
            content_id: contentId,
        };

        const videoTag = this.videoNode;
        // {liveSyncDuration: 5,1002
        //     liveMaxLatencyDuration: 9,
        //     maxLiveSyncPlaybackRate: 1,
        //     liveDurationInfinity: !0,}
        this.player = new Hls({
            lowLatencyMode: true,
        });
        this.playerFunctions = getPlayerControls({
            playerType: PLAYER_TYPES.SHAKA_HLS_PLAYER,
            player: this.player,
            videoTag,
            isLiveStream: streamType === STREAM_TYPES.LIVE,
            contentDetails,
        });

        this.playerEvents = getPlayerEvents({
            playerType: PLAYER_TYPES.SHAKA_HLS_PLAYER,
            player: this.player,
            videoTag,
            playerFunctions: this.playerFunctions,
        });

        // let adTagUrl;
        // const adTagUrl = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/423477888/Airtel_Xstreme_Web_Test&description_url=[placeholder]&tfcd=0&npa=0&sz=640x480&ciu_szs=728x90%2C320x50&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=&cmsid=2529424&vid=[vid]';
        // const adTagUrl = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=';
        if (adTagUrl && this.isWeb) {
            PlayerAnalyticsQueue.startAnalyticsEventsQueue();
            console.log('Hls player addTagUrl', adTagUrl);
            this.videoNode.addEventListener('play', (event) => {
                this.loadAds(event);
            });

            window.addEventListener('resize', (event) => {
                const videoNode = this.videoNode || document.getElementById(VIDEO_TAG_ID);
                if (this.adsManager && videoNode) {
                    const width = videoNode.clientWidth;
                    const height = videoNode.clientHeight;
                    console.log('window resized', videoNode, width, height);
                    this.adsManager.resize(width, height, google.ima.ViewMode.NORMAL);
                }
            });

            this.adDisplayContainer = new google.ima.AdDisplayContainer(this.adContainer, this.videoNode);


            this.videoNode.addEventListener('ended', () => {
                this.adsLoader?.contentComplete();
            });
            this.adsLoader = new google.ima.AdsLoader(this.adDisplayContainer);
            this.adsLoader.addEventListener(
                google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
                event => this.onAdsManagerLoaded(event),
                false,
            );
            // adsLoader.addEventListener(
            //     google.ima.AdErrorEvent.Type.AD_ERROR,
            //     function() {console.log("---->bye")},
            //     false);
            const adsRequest = new google.ima.AdsRequest();
            adsRequest.adTagUrl = adTagUrl;
            // Specify the linear and nonlinear slot sizes. This helps the SDK to
            // select the correct creative if multiple are returned.
            adsRequest.linearAdSlotWidth = this.videoNode.clientWidth;
            adsRequest.linearAdSlotHeight = this.videoNode.clientHeight;
            adsRequest.nonLinearAdSlotWidth = this.videoNode.clientWidth;
            adsRequest.nonLinearAdSlotHeight = this.videoNode.clientHeight / 3;

            this.adsLoader.requestAds(adsRequest);
            await this.startLiveStream(adsAnalyticsMeta);
        }
        else {
            await this.startLiveStream(adsAnalyticsMeta);
        }
    }

    async startLiveStream(adsAnalyticsMeta) {
        const {
            playbackConfig,
            playbackConfig: { useLegacyShaka = false } = {},
            playbackDetails,
            dispatchGetAdsPlaying,
        } = this.props;


        const {
            daiAssetKey,
        } = playbackConfig || {};

        // if no dia asset key then player is returning
        if (!daiAssetKey) {
            return;
        }

        let timerElement = window.document.getElementById('ad-timer');

        const contentName = lodashGet(playbackConfig, 'videoMeta.videoTitle', '');
        const isdrm = lodashGet(playbackConfig, 'isDrm', '');
        const contentId = lodashGet(playbackConfig, 'contentId', '');
        const cpName = lodashGet(playbackDetails, 'cp', '');
        const contentLanguage = lodashGet(playbackDetails, 'plg', '');
        const playbackContentType = lodashGet(playbackDetails, 'playbackType', '');
        const streamManager = new google.ima.dai.api.StreamManager(this.videoNode, this.videoWrapperRef);
        streamManager.addEventListener(
            [
                google.ima.dai.api.StreamEvent.Type.LOADED,
                // google.ima.dai.api.StreamEvent.Type.ERROR,
                // google.ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED,
                // google.ima.dai.api.StreamEvent.Type.AD_BREAK_ENDED
            ],
            this.onStreamEvent,
        );
        window.sm = streamManager;
        streamManager.addEventListener(google.ima.dai.api.StreamEvent.Type.ERROR, () => {
            adErrorEvent(adsAnalyticsMeta);
        });
        streamManager.addEventListener(google.ima.dai.api.StreamEvent.Type.LOADED, this.onStreamEvent);

        streamManager.addEventListener(google.ima.dai.api.StreamEvent.Type.AD_BREAK_STARTED, (e) => {
            const adsPlayingFlag = true;
            const { SERVER_SIDE_AD = 'SERVER_SIDE_AD' } = AD_TYPE;
            console.log('====> ads DAI AD_BREAK_STARTED');
            adLoadEvent(adsAnalyticsMeta);
            const container = document.getElementsByClassName('vdo-player__wrapper');
            const adIframe = container[0].getElementsByTagName('iframe');
            // adIframe[0].style.display = 'none';
            if (adIframe && adIframe.length) {
                adIframe[0].classList.add('adIframe');
            }
            if (!this.isImaAdPlaying) {
                dispatchGetAdsPlaying({
                    adsPlayingFlag,
                    adType: SERVER_SIDE_AD,
                });
            }
        });
        streamManager.addEventListener(google.ima.dai.api.StreamEvent.Type.STARTED, (e) => {
            console.log('started', e.getAd(), e.getAd().getDuration(), e.getAd().getAdPodInfo().getAdPosition(), e.getStreamData().progressData);
            console.log('ll', 'ad complete');
            console.log('====> ads DAI ad STARTED');


            const ad = e.getAd();
            const adId = ad.getAdId();
            const adTitle = ad.getTitle();
            adStartedEvent(adsAnalyticsMeta);
        });


        streamManager.addEventListener(google.ima.dai.api.StreamEvent.Type.COMPLETE, (e) => {
            adEndedEvent(adsAnalyticsMeta);
        });

        streamManager.addEventListener(google.ima.dai.api.StreamEvent.Type.AD_PROGRESS, (e) => {
            if (this.isImaAdPlaying) {
                return;
            }
            const progressData = e.getStreamData().adProgressData;
            const {
                duration, adPosition, totalAds, currentTime,
            } = progressData;
            const timeRemaining = Math.ceil(duration - currentTime);
            let min = Math.floor(timeRemaining / 60);
            let sec = timeRemaining % 60;
            min = min < 10 ? `0${min}` : min;
            sec = sec < 10 ? `0${sec}` : sec;
            timerElement = document.getElementById('ad-timer');
            console.log('====> ads DAI ad ADD_PROGRESS');
            timerElement && (timerElement.innerHTML = `Ad ${adPosition} of ${totalAds} (${min}:${sec})`);
        });

        streamManager.addEventListener(google.ima.dai.api.StreamEvent.Type.AD_BREAK_ENDED, (e) => {
            const adsPlayingFlag = false;
            dispatchGetAdsPlaying({
                adsPlayingFlag,
                adType: '',
            });
            adBreakEndEvent(adsAnalyticsMeta);
        });

        this.hls = this.player;
        this.hls.on(Hls.Events.FRAG_PARSING_METADATA, (event, data) => {
            if (streamManager && data) {
            // For each ID3 tag in our metadata, we pass in the type - ID3, the
            // tag data (a byte array), and the presentation timestamp (PTS).
                data.samples.forEach((sample) => {
                    streamManager.processMetadata('ID3', sample.data, sample.pts);
                });
            }
        });
        const streamRequest = new google.ima.dai.api.LiveStreamRequest();
        // streamRequest.assetKey = 'c-rArva4ShKVIAkNfy6HUQ';
        // streamRequest.assetKey = 'c-rArva4ShKVIAkNfy6HUQ';
        // '4FwpwmqhQuaWR5vZCFSy6w'
        // 'sN_IYUG8STe1ZzhIIE_ksA
        streamRequest.assetKey = daiAssetKey;

        streamManager.requestStream(streamRequest);
        this.enableControlBar();
        this.installKeyboardControls();
        this.playerEventsHandler();


        const { playerEventsInitialized } = this.props;
        if (playerEventsInitialized) {
            await playerEventsInitialized(this.playerEvents, this.playerFunctions, useLegacyShaka);
        }
        this.playerLoaded = true;
    }

    async initConfig(reload) {
        const { playbackConfig: { useLegacyShaka = false } = {}, playbackDetails, deviceUtil: { isIOS } } = this.props;

        if (!isIOS()) {
            // Install built-in polyfills to patch browser incompatibilities.
            // shaka.polyfill.installAll();
            const videoRef = this.videoNode;
            if (this.videoNode) {
                this.videoNode.src = 'static/test.mp4';
            }
            /**
              * Attempts autoplay and handles success and failure cases.
              */
            // eslint-disable-next-line no-inner-declarations
            function checkAutoplaySupport() {
                // Test for autoplay support with our content player.
                if (videoRef) {
                    videoRef.opacity = 0;
                }
                const playPromise = videoRef.play();
                if (playPromise !== undefined) {
                    // eslint-disable-next-line no-use-before-define
                    playPromise.then(onAutoplayWithSoundSuccess).catch(onAutoplayWithSoundFail);
                }
            }
            /**
          * Handles case where autoplay succeeded with sound.
          */
            const onAutoplayWithSoundSuccess = () => {
            // If we make it here, unmuted autoplay works.
                videoRef.pause();
                this.videoNode.removeAttribute('src');
                // request for ads
                this.videoNode.opacity = 1;
                this.initPlayer();
            };

            /**
          * Handles case where autoplay fails with sound.
          */
            const onAutoplayWithSoundFail = (e) => {
            // Unmuted autoplay failed. Now try muted autoplay.
                // eslint-disable-next-line no-use-before-define
                checkMutedAutoplaySupport();
            };

            /**
          * Checks if video can autoplay while muted.
          */
            const checkMutedAutoplaySupport = () => {
                videoRef.volume = 0;
                videoRef.muted = true;
                const playPromise = videoRef.play();
                if (playPromise !== undefined) {
                    // eslint-disable-next-line no-use-before-define
                    playPromise.then(onMutedAutoplaySuccess).catch(onMutedAutoplayFail);
                }
            };

            /**
          * Handles case where autoplay succeeded while muted.
          */
            const onMutedAutoplaySuccess = () => {
                videoRef.volume = 0;
                videoRef.muted = false;
                if (this.videoNode) {
                    this.videoNode.removeAttribute('src');
                    // request for ads
                    this.videoNode.opacity = 1;
                }
                this.initPlayer(reload);
            // videoRef.play();
            };

            /**
          * Handles case where autoplay failed while muted.
          */
            const onMutedAutoplayFail = (e) => {
                // Both muted and unmuted autoplay failed. Fall back to click to play.
                videoRef.volume = 1;
                videoRef.muted = false;
                if (this.videoNode) {
                    this.videoNode.removeAttribute('src');
                    // request for ads
                    this.videoNode.opacity = 1;
                }
                this.initPlayer(reload);
            };

            checkAutoplaySupport();
            // Everything looks good!
            // this.initPlayer();
            // this.initPlayer(reload);
            // Everything looks good!
        }
        else if (isIOS()) {
            this.initPlayer(reload);
        }
    }
}


HlsPlayerComponentV2.propTypes = {
    browserNotSupported: PropTypes.func.isRequired,
    reload: PropTypes.func,
    playerEventsInitialized: PropTypes.func,
    ControlBar: PropTypes.func,
    playbackDetails: PropTypes.object,
    playbackConfig: PropTypes.shape({
        playbackUrlCookies: PropTypes.object,
        browser: PropTypes.string,
        browserVersion: PropTypes.number,
        os: PropTypes.string,
        enableAutoplay: PropTypes.bool,
        enableNativeControls: PropTypes.bool,
        isDrm: PropTypes.bool,
        contentId: PropTypes.string,
        playbackUrl: PropTypes.string,
        playbackType: PropTypes.string.isRequired,
        drm: PropTypes.shape({
            drmType: PropTypes.string,
            handleLicenseRequest: PropTypes.func,
            handleLicenseResponse: PropTypes.func,
            licenseUri: PropTypes.string,
            handleCertificateRequest: PropTypes.func, // for fairplay
            certificateUri: PropTypes.string, // for fairplay
        }),
    }),
    deviceUtil: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    playTrailer: PropTypes.bool,
};

HlsPlayerComponentV2.defaultProps = {
    reload: () => null,
    playerEventsInitialized: () => null,
    ControlBar: () => null,
    playbackDetails: {},
    playbackConfig: {
        playbackUrlCookies: null,
        browser: '',
        browserVersion: '',
        os: '',
        enableAutoplay: false,
        playbackUrl: '',
        enableNativeControls: false,
        isDrm: false,
        contentId: '',
        drm: {
            drmType: '',
            handleLicenseRequest: undefined, // if provided then player will call this funtion to get license instead of get license itself
            handleLicenseResponse: undefined,
            licenseUri: '',
            handleCertificateRequest: undefined, // for fairplay
            certificateUri: '', // for fairplay
            ticket: '',
        },
    },
};

const mapStateToProps = (state) => {
    const {
        appConfig,
        playbackDetails,
        appConfig: { tagDetails, playback_soft_nudge_timer_list: playbackSoftNudgeTimerList },
        contentDetails: { playableId = '' },
    } = state;
    const adsData = lodashGet(appConfig, 'adsData', {});
    const adsPlayingFlag = lodashGet(playbackDetails, 'adsPlayingFlag', false);
    const { additionalProperties = {} } = playbackDetails[playableId] || {};
    const playbackTagId = additionalProperties?.tagId || '';
    const playbackTagConfig = playbackTagId ? tagDetails[playbackTagId] : null;
    // const mockPlaybackTagConfig = tagDetails['FREE_PLAYBACK_TAG_ID'];
    return {
        adsData,
        adsPlayingFlag,
        playbackTagConfig,
        playbackSoftNudgeTimerList,
        //  mockPlaybackTagConfig
    };
};

export default connect(mapStateToProps, {
    dispatchGetAdsPlaying: adsPlaying,
    updateKeyboardControlsActionDispatch: updateKeyboardControlsAction,
    dispatchSdkAdsPlaying: setSdkAdsPlaying,
})(withDeviceUtil(HlsPlayerComponentV2));
