/* eslint-disable no-throw-literal */
import lodashGet from 'lodash/get';
import {
    call, put, select, getContext,
    delay,
} from 'redux-saga/effects';
import { BROWSER_PLAYBACK_TYPE, PLAYBACKS_FORMATS } from '@airtel-tv/constants/BrowserConst';
import { takeFirst } from '@airtel-tv/utils/ReduxSagaUtil';
import {
    isOnline, redirectTo, handleOpenApp, openExternalLGApp,
} from '@airtel-tv/utils/WindowUtil';
import { ERROR_CODES, NOTIFYIDS } from '@airtel-tv/constants/ErrorCodes';
import { HEART_BEAT_FALLBACK_INTERVAL } from '@airtel-tv/constants/PlaybackConsts';
import { playbackErrorGaEvent } from '@airtel-tv/utils/GaEvents';
import { getAppId, getCpSubscriptionStatusMeta } from '@airtel-tv/utils/GlobalUtil';
import {
    getAppConfigFromReducer, getAuthConfigFromReducer, getHomePageConfigFromReducer, getPlansAndSubscriptionFromReducer, getUserConfigFromReducer,
} from '@airtel-tv/redux/StoreListing';
import { MODAL_POPUP, ANALYTICS_CATEGORY } from '@airtel-tv/constants/GlobalConst';
import { DeviceTypeUtil, LocationUtil } from '@airtel-tv/utils';
import browserStore from '@airtel-tv/utils/BrowserStoreUtil';
import { appInAppRedirectionEvent } from '@airtel-tv/analytics/FunctionalEvents';
import { EVENT_SOURCE_NAME } from '@airtel-tv/constants/EventsConst';
import { CONTENT_PROVIDERS } from '@airtel-tv/constants';
import PlaybackParamsBuilder from '@airtel-tv/utils/PlaybackParamsBuilder';
import ScopedWebviewUtil from '@airtel-tv/utils/ScopedWebviewUtil';
import ApiAnalytics from '../helpers/ApiAnalytics';
import env from '../../../web/src/config';
import { showModalComponentAction } from '../../../web/src/modules/modal-popup/ModalPopupActions';
import { refreshTokenSuccess, clearAccessToken } from '../../../web/src/modules/auth/AuthAction';
import { refreshToken } from '../../../web/src/service/end-points/AuthApiService';
import {
    PlaybackDetailsActions,
    playbackDetailsFetchCompletedAction,
    playbackDetailsFetchErrorAction,
    playbackDetailsFetchCookiesCompletedAction,
    playbackClearAllAction,
    concurrentPlayLimitError,
    appInAppHandling,
} from '../actions/PlaybackActions';
import { getPlayback, sendHeartBeat, getPlaybackWithoutLogin } from '../../../libraries/services/PlaybackApiService';
import { ErrorHandler } from '../factories/PlaybackUiComponentFactory';
import { setPlayerConfig } from '../../../tv/src/modules/homepage/actions/QuickViewActions';
import { resetPlaybackDetails } from '../actions/PlaybackActions';


const mockPlaybackNadHeartbeatError = () => {
    const heartbeatpopup = localStorage.getItem('_HEARTBEATINTERVALERROR_');
    if (heartbeatpopup) {
        throw {
            success: false,
            notifyId: heartbeatpopup, // 'XPP_PURCHASE_POP_UP_ID',
            appErrorTitle: 'Play Error 102',
            appErrorMessage: 'Unable to play requested content. Please contact Helpdesk',
            errorcode: 'ATV206',
            errortitle: 'Unable to Register this device',
        };
    }
    const playbackpopup = localStorage.getItem('_PLAYBACK_FMF_SI_ERROR_');
    if (playbackpopup) {
        throw {
            success: false,
            notifyId: playbackpopup, // 'XPP_PURCHASE_POP_UP_ID',
            appErrorTitle: 'Play Error 102',
            appErrorMessage: 'Unable to play requested content. Please contact Helpdesk',
            errorcode: 'ATV202',
            errortitle: 'Unable to Register this device',
        };
    }

    if (localStorage.getItem('_SHOW_CONSENT_POPUP_ADULT_')) {
        throw {
            notifyId: 'AGE_CONSENT_POPUP_ADULT',
            errorcode: 'ATV213',
        };
    }
};
function* handleAppInAppRedirection({
    appInAppMeta, contentId, cpId = '', appInstalled = false, lgAppId = '',
}) {
    const deviceUtil = yield getContext('deviceUtil');
    const plansAndSubscriptionData = yield select(getPlansAndSubscriptionFromReducer);
    const {
        svodPlans: {
            all_plans: {
                planClaimStatusClient = {},
            } = {},
        } = {},
    } = plansAndSubscriptionData;
    const cpSubscriptionStatusMeta = getCpSubscriptionStatusMeta(planClaimStatusClient, cpId);
    const {
        android_redirect_url: androidRedirectUrl = '',
        ios_redirect_url: iosRedirectUrl = '',
        browser_redirect_url: browserRedirectUrl = '',
        android_app_id: androidAppId = '',
        ios_app_id: iosAppId = '',
        lg_redirect_url: lgRedirectUrl = '',
        cpContentId = '',
    } = appInAppMeta;
    let appId = `${cpId}_web`;
    let deeplinkUrl = '';
    const appModeOnline = isOnline();
    const meta = {
        content_id: contentId,
        app_id: appId,
        deeplink_url: deeplinkUrl,
        app_installed: appInstalled,
        cpId,
        app_mode_online: appModeOnline,
        source_name: EVENT_SOURCE_NAME.CONTENT_DETAIL_PAGE_V2,
        cp_name: cpId,
        ...cpSubscriptionStatusMeta,
    };
    appInAppRedirectionEvent(meta);
    if (lgAppId) {
        if (cpId === 'ZEEFIVE') {
            openExternalLGApp({
                lgAppId,
                params: {
                    tagId: lgRedirectUrl,
                    contentId: cpContentId,
                },
            });
        }
        else if (cpId === 'AMAZON_PRIME') {
            openExternalLGApp({
                lgAppId,
                params: {
                    contentTarget: lgRedirectUrl,
                },
            });
        }
        return;
    }
    if (deviceUtil.isMobile()) {
        if (deviceUtil.isIOS()) {
            appId = iosAppId;
            deeplinkUrl = iosRedirectUrl;
        }
        else {
            appId = androidAppId ? `https://play.google.com/store/apps/details?id=${androidAppId}` : '';
            deeplinkUrl = androidRedirectUrl;
        }
        if (cpId === 'ZEEFIVE') {
            appId = '';
        }
    }
    else {
        const fallBrowserDeeplink = androidRedirectUrl.startsWith('https') ? androidRedirectUrl : '';
        deeplinkUrl = browserRedirectUrl || fallBrowserDeeplink;
    }
    if (deviceUtil.isMobile() && appId) {
        handleOpenApp({
            appPath: deeplinkUrl,
            storePath: appId,
        });
    }
    else {
        redirectTo(deeplinkUrl);
    }
}

function* sendHeartbeatSaga({ contentId, retryActionPayload }) {
    const deviceUtil = yield getContext('deviceUtil');

    const authInfo = yield select(getAuthConfigFromReducer);

    const atvDid = deviceUtil.getXAtvDid();
    try {
        const response = yield call(sendHeartBeat, {
            contentId,
            atvDid,
            uid: authInfo.uid,
            token: authInfo.token,
            appId: getAppId(),
        });
        const {
            success,
            deviceLimitExceeded,
            deviceResponse: { devices = [] } = {},
            heartbeatMap: { interval = 0 } = {},
            interval: intervalFallBack = HEART_BEAT_FALLBACK_INTERVAL,
        } = response;

        const heartBeatInterval = interval || intervalFallBack;
        browserStore.set('heartBeatInterval', JSON.stringify({
            [`${contentId}`]: heartBeatInterval,
        }));

        mockPlaybackNadHeartbeatError();

        if (!success && deviceLimitExceeded && devices?.length) {
            // update data
            const payload = {
                concurrentPlaybackLimitError: {
                    ...response,
                    retryPayload: retryActionPayload,
                    contentId,
                    lastUpdatedTimeStamp: Date.now(),
                },
                [contentId]: {},
                lastUpdatedTimeStamp: Date.now(),
                closePlayer: true,
            };
            yield put(resetPlaybackDetails());
            yield put(concurrentPlayLimitError({ payload }));
            throw {
                notifyId: NOTIFYIDS.CONCURRENT_PLAYBACK_LIMIT_POPUP,
                errorcode: 'concurrentError',
                errortitle: 'Concurrent Limit Error',
            };
            // return heartBeatInterval;
        }
        yield put(concurrentPlayLimitError({
            payload: {
                concurrentPlaybackLimitError: {},
                lastUpdatedTimeStamp: Date.now(),
            },
        }));
        return heartBeatInterval;
    }
    catch (error) {
        console.error(error);
        const e = error.data ? error.data : error;
        if (e && e.errorcode) {
            ErrorHandler({
                error: e,
                code: e.errorcode,
                errorTitle: e.appErrorMessage,
                contentId,
                resetLayout: true,
            });
        }

        return 0;
    }
}

function* refreshTokenRetryPlaybackSaga({
    contentId, callback, errorActionSaga, callbackParam = {},
}) {
    const authInfo = yield select(getAuthConfigFromReducer);
    try {
        const deviceUtil = yield getContext('deviceUtil');
        const {
            token: newAccessToken,
        } = yield call(refreshToken, {
            uid: authInfo.uid,
            accessToken: authInfo.accessToken,
            token: authInfo.token,
            deviceUtil,
            contentId,
        });

        // UPDATE TOKEN IN REDUX STORE
        yield put(refreshTokenSuccess({ accessToken: newAccessToken }));

        // TRY FETCH PLAYBACK CALL WITH NEW TOKEN
        yield call(callback, {
            contentId,
            closeRecursion: true,
            callbackParam,
        });
    }
    catch (refreshTokenError) {
        console.error('unable to refresh token', refreshTokenError);
        if (errorActionSaga) {
            let error = refreshTokenError.data ? refreshTokenError.data : refreshTokenError;

            if (!isOnline()) {
                error = { errorcode: ERROR_CODES.PLAYER_NETWORK_ERROR };
            }

            const payload = {
                [contentId]: {
                    error,
                },
            };

            yield put(errorActionSaga({ payload }));

            // clear access token from store
            yield put(clearAccessToken());
        }
    }
}

function* fetchPlaybackDetails(action) {
    const deviceUtil = yield getContext('deviceUtil');

    const {
        contentId, closeRecursion, preLoad = false, retry = false,
        useAppInApp = true, cpId, isExternalPlayer = false,
        appInAppRedirection = false, playbackParams = {}, callbackParam = {}, scopedWebviewCallback,
        byPassAcessToken = false, appIdOverride = '',
        appInAppParams,
        lgAppId,
    } = action;

    const authInfo = yield select(getAuthConfigFromReducer);
    const userInfo = yield select(getUserConfigFromReducer);
    const browser = deviceUtil.getBrowserName();
    const playType = BROWSER_PLAYBACK_TYPE[browser] || (DeviceTypeUtil.isWeb() ? PLAYBACKS_FORMATS.HLS : PLAYBACKS_FORMATS.DASH);
    const { customerType, userInfo: { currSeg } } = userInfo;
    const networkType = deviceUtil.getNetworkType();
    const atvCustomer = deviceUtil.getAtvCustomer(customerType, networkType);
    let apiAnalytics = null;
    const { base, paths } = env.endpoints.playbackApi;
    const appId = appIdOverride || getAppId();
    const queryParams = {
        contentId,
        appId,
    };
    const queryParamsString = LocationUtil.objectToQueryParams(queryParams);
    const url = `${base}${paths.getPlayback}?${queryParamsString}`;
    apiAnalytics = new ApiAnalytics({ contentId }, {
        url,
        category: ANALYTICS_CATEGORY.PLAYBACK,
    });
    const { playbackParams: callbackPlaybackParams = {} } = callbackParam;
    let apiServiceParams = Object.keys(playbackParams).length > 0 ? playbackParams : callbackPlaybackParams;
    apiServiceParams = {
        ...(apiServiceParams.appBundle && { __APP_BUNDLE__: apiServiceParams.appBundle }),
        ...(apiServiceParams.appCategory && { __APP_CATEGORY__: apiServiceParams.appCategory }),
        ...(apiServiceParams.appDomain && { __APP_DOMAIN__: apiServiceParams.appDomain }),
        ...(apiServiceParams.appName && { __APP_NAME__: apiServiceParams.appName }),
        ...(apiServiceParams.appVersion && { __APP_VERSION__: apiServiceParams.appVersion }),
        ...(apiServiceParams.playerWidth && { __WIDTH__: apiServiceParams.playerWidth }),
        ...(apiServiceParams.playerHeight && { __HEIGHT__: apiServiceParams.playerHeight }),
        ...(apiServiceParams.cacheBuster && { __CACHE_BUSTER__: apiServiceParams.cacheBuster }),
        ...(apiServiceParams.distroPlaySessionId && { __PLAYBACK_ID__: apiServiceParams.distroPlaySessionId }),
        ...(apiServiceParams.userAgent && { __USER_AGENT__: apiServiceParams.userAgent }),
        ...(apiServiceParams.deviceMake && { __DEVICE_MAKE__: apiServiceParams.deviceMake }),
        ...(apiServiceParams.pageUrl && { __PAGEURL_ESC__: apiServiceParams.pageUrl }),
        ...(apiServiceParams.advertisingId && { __ADVERTISING_ID__: apiServiceParams.advertisingId }),
        ...(apiServiceParams.watchPercentage && { watchPercentage: apiServiceParams.watchPercentage }),
        ...(apiServiceParams.contentLengthInSec && { contentLengthInSec: apiServiceParams.contentLengthInSec }),
        ...(apiServiceParams.miniTvDeviceType && { deviceType: apiServiceParams.miniTvDeviceType }),
        ...appInAppParams, // params for zee5 appinapp rediection
    };
    try {
        if (!appInAppRedirection) {
            apiAnalytics.fireApiInit();
        }
        // GET PLAY PLAYBACK RESPONSE
        let playbackDetails = yield call(getPlayback, {
            contentId,
            uid: authInfo.uid,
            playType,
            customerType: atvCustomer,
            atvSegment: currSeg,
            accessToken: authInfo.accessToken,
            token: authInfo.token,
            deviceUtil,
            apiServiceParams,
            byPassAcessToken,
            appIdOverride,
        });
        mockPlaybackNadHeartbeatError();

        let heartBeatInterval = 10;

        const {
            success: playbackRespSuccess,
            deviceLimitExceeded,
            deviceResponse,
            playbackType,
            cp,
        } = playbackDetails;
        if (!appInAppRedirection) {
            if (!playbackRespSuccess && deviceLimitExceeded && deviceResponse?.devices?.length) {
                const payload = {
                    concurrentPlaybackLimitError: {
                        ...playbackDetails,
                        retryPayload: action,
                        contentId,
                    },
                    [contentId]: {},
                    lastUpdatedTimeStamp: new Date().getTime(),
                    closePlayer: true,
                };
                yield put(concurrentPlayLimitError({ payload }));
                const error = {
                    notifyId: NOTIFYIDS.CONCURRENT_PLAYBACK_LIMIT_POPUP,
                    errorcode: 'concurrentError',
                    errortitle: 'Concurrent Limit Error',
                };
                ErrorHandler({
                    code: error.errorcode,
                    errorTitle: error.errortitle,
                    contentId,
                    error,
                });
                return;
            }
            // yield put(concurrentPlayLimitError({
            //     payload: {
            //         concurrentPlaybackLimitError: {},
            //         lastUpdatedTimeStamp: Date.now(),
            //     },
            // }));
            apiAnalytics.setResponseTime(playbackDetails.api_response_time);
            apiAnalytics.fireApiResponse();
            // send first heart beat
            heartBeatInterval = !DeviceTypeUtil.isWeb() && preLoad ? 10 : yield call(sendHeartbeatSaga, {
                contentId,
                retryActionPayload: action,
            });
        }

        // PUT PLAYBACK DATA IN STORE
        const payload = {
            [contentId]: {
                ...playbackDetails,
                // ads: {
                //     source: 'GOOGLE_IMA',
                //     meta: {
                //         url: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/423477888/Airtel_xstreme_VOD_IOS&url=%7B0%7D&description_url=%7B1%7D&cmsid=2530476&env=vp&impl=s&correlator=1234&tfcd=0&npa=0&gdfp_req=1&output=vmap&sz=640x480&unviewed_position_start=1&vid=1000270798&plcmt=1&vpmute=0&correlator=63456134612345123451245124524',
                //         type: 'PRE',
                //     },
                // },
                heartBeatInterval,
                expireCount: Infinity, // default n number of times OLD playback call can be used
            },
            lastUpdatedTimeStamp: Date.now(),
            ...(!heartBeatInterval ? {} : { concurrentPlaybackLimitError: {} }),
            closePlayer: false,
        };

        yield put(playbackDetailsFetchCompletedAction({ payload }));
        if (playbackType === 'APP_IN_APP' && !isExternalPlayer && useAppInApp) {
            if (!DeviceTypeUtil.isTizen() || lgAppId) {
                const appInAppMeta = playbackDetails?.playback.appInAppMeta;
                yield put(appInAppHandling({
                    appInAppMeta,
                    contentId,
                    cpId: cp || cpId,
                    lgAppId,
                }));
            }
            return;
        }
    }
    catch (e) {
        console.error(e);
        let api_response_time;
        if (e.customResponse) {
            api_response_time = e.api_response_time;
            e = e.message;
        }
        else {
            api_response_time = e.api_response_time;
        }
        const error = e.data ? e.data : e;
        const { headers, request } = e || {};
        let payload = {
            [contentId]: {
                error,
                fetching: false,
            },
        };
        if (byPassAcessToken) {
            ErrorHandler({
                showToastOnly: true,
            });
        }
        const { errorcode = '', appErrorMessage = '' } = error || { };
        // handle error codes here
        if (deviceUtil.isScopedWebview() && scopedWebviewCallback && !([
            ERROR_CODES.ATV252,
            ERROR_CODES.ATV043,
            ERROR_CODES.ATV213,
        ].includes(errorcode))) {
            scopedWebviewCallback();
            payload = {
                [contentId]: {
                    fetching: false,
                },
            };
            yield put(playbackDetailsFetchErrorAction({ payload }));

            return;
        }
        if (error && !preLoad && errorcode !== ERROR_CODES.ATV043) {
            const showToastOnly = ![
                ERROR_CODES.ATV252,
            ].includes(errorcode);

            const goBack = [
                ERROR_CODES.ATV252,
            ].includes(errorcode);

            ErrorHandler({
                code: errorcode,
                errorTitle: appErrorMessage,
                contentId,
                error,
                showToastOnly,
                goBack,
                // resetLayout: true,
            });
            if (goBack) {
                payload = {
                    [contentId]: {
                        fetching: false,
                    },
                };
            }
        }

        playbackErrorGaEvent({ errorcode });
        const homepageInfo = yield select(getHomePageConfigFromReducer);
        const { playerConfig: { playbackInForeGround = false, startPlayback = false } = {} } = homepageInfo || {};
        if (!playbackInForeGround && startPlayback) { // code to call playback api on watch now buttonclicked (largescreen) after playback api gave error
            yield put(setPlayerConfig({ startPlayback: false }));
        }

        // IF ACCESS TOKEN IS EXPIRED THEN REFRESH TOKEN AND RETRY IF ITS NOT ALREADY DONE (FLAG: closeRecursion)
        if (error.errorcode === ERROR_CODES.ATV043 && !closeRecursion && !byPassAcessToken) {
            apiAnalytics.setResponseTime(api_response_time);
            apiAnalytics.fireApiResponse(error, headers, request);
            yield call(refreshTokenRetryPlaybackSaga, {
                contentId,
                callback: fetchPlaybackDetails,
                errorActionSaga: !preLoad ? playbackDetailsFetchErrorAction : null,
                callbackParam: {
                    playbackParams,
                },
            });
        }
        // SOME ERROR OCCURRED SO PUT ERROR IN STORE
        else if (preLoad) {
            yield put(playbackClearAllAction());
        }
        else {
            apiAnalytics.setResponseTime(api_response_time);
            apiAnalytics.fireApiResponse(error, headers, request);
            yield put(playbackDetailsFetchErrorAction({ payload }));
        }
    }
}


function* fetchPlaybackCookie(action) {
    const { contentId, closeRecursion, playWithoutLogin = false } = action;

    try {
        const deviceUtil = yield getContext('deviceUtil');

        const browser = deviceUtil.getBrowserName();
        const playType = BROWSER_PLAYBACK_TYPE[browser] || (DeviceTypeUtil.isWeb() ? PLAYBACKS_FORMATS.HLS : PLAYBACKS_FORMATS.DASH);

        const authInfo = yield select(getAuthConfigFromReducer);

        const userInfo = yield select(getUserConfigFromReducer);

        const { customerType, userInfo: { currSeg } } = userInfo;

        const networkType = deviceUtil.getNetworkType();
        const atvCustomer = deviceUtil.getAtvCustomer(customerType, networkType);

        const playbackDetails = !playWithoutLogin ? yield call(getPlayback, {
            contentId,
            uid: authInfo.uid,
            playType,
            customerType: atvCustomer,
            atvSegment: currSeg,
            accessToken: authInfo.accessToken,
            token: authInfo.token,
            deviceUtil,
        }) : yield call(getPlaybackWithoutLogin, {
            contentId,
            uid: authInfo.uid,
            playType,
            customerType: atvCustomer,
            atvSegment: currSeg,
            token: authInfo.token,
            deviceUtil,
        });

        const cookie = lodashGet(playbackDetails, 'playback.headers.Cookie');

        if (cookie) {
            yield put(playbackDetailsFetchCookiesCompletedAction({
                contentId,
                cookie,
            }));
        }
    }
    catch (e) {
        console.error('unable to refresh cookie', e);

        const error = e.data ? e.data : e;

        // IF ACCESS TOKEN IS EXPIRED THEN REFRESH TOKEN AND RETRY IF ITS NOT ALREADY DONE (FLAG: closeRecursion)
        if (error.errorcode === ERROR_CODES.ATV043 && !closeRecursion) {
            yield call(refreshTokenRetryPlaybackSaga, {
                contentId,
                callback: fetchPlaybackCookie,
            });
        }
    }
}

const getPlaybackWithoutLoginUrl = ({
    contentId, isTrailer,
}) => {
    const appId = getAppId();
    const { base, paths } = env.endpoints.playbackApi;
    const queryParams = {
        contentId,
        appId,
    };
    const queryParamsString = LocationUtil.objectToQueryParams(queryParams);
    const path = isTrailer ? paths.playbackTrailer : paths.playWithoutLogin;
    const url = `${base}${path}?${queryParamsString}`;
    return url;
};

function* fetchPlaybackWithoutLogin(action) {
    const deviceUtil = yield getContext('deviceUtil');

    const { contentId, closeRecursion, callbackParam } = action;
    let {
        isTrailer, isTrailerTile, hideToast = false, contentDetails = {},
    } = action;
    if (callbackParam) {
        isTrailer = isTrailer || callbackParam.isTrailer;
        isTrailerTile = isTrailerTile || callbackParam.isTrailerTile;
        contentDetails = contentDetails || callbackParam.contentDetails;
    }
    const authInfo = yield select(getAuthConfigFromReducer);
    const userInfo = yield select(getUserConfigFromReducer);
    const browser = deviceUtil.getBrowserName();
    const playType = BROWSER_PLAYBACK_TYPE[browser] || (DeviceTypeUtil.isWeb() ? PLAYBACKS_FORMATS.HLS : PLAYBACKS_FORMATS.DASH);
    const { customerType, userInfo: { currSeg } } = userInfo;
    const networkType = deviceUtil.getNetworkType();
    const atvCustomer = deviceUtil.getAtvCustomer(customerType, networkType);
    let apiServiceParams = {};
    let apiAnalytics = null;
    try {
        const url = getPlaybackWithoutLoginUrl({
            contentId,
            isTrailer,
        });
        if (!isTrailerTile) {
            apiAnalytics = new ApiAnalytics({ contentId }, {
                url,
                category: ANALYTICS_CATEGORY.PLAYBACK_TRAILER,
            });
            apiAnalytics.fireApiInit();
        }
        if (contentDetails?.cpId === CONTENT_PROVIDERS.MINITV) {
            const { CP_CONTROL_CONFIG = {} } = yield select(getAppConfigFromReducer) || {};
            const miniTvDeviceDetails = PlaybackParamsBuilder.getDeviceDetails({
                controlConfig: CP_CONTROL_CONFIG[CONTENT_PROVIDERS.MINITV],
                deviceUtil,
            });
            apiServiceParams = {
                watchPercentage: 0,
                contentLengthInSec: 1,
                deviceType: miniTvDeviceDetails?.deviceType,
            };
        }

        const playbackDetails = yield call(getPlaybackWithoutLogin, {
            contentId,
            uid: authInfo.uid,
            playType,
            customerType: atvCustomer,
            atvSegment: currSeg,
            accessToken: authInfo.accessToken,
            token: authInfo.token,
            deviceUtil,
            isTrailer,
            ...(Object.keys(apiServiceParams).length && { apiServiceParams }),
        });
        if (!isTrailerTile) {
            apiAnalytics.setResponseTime(playbackDetails.api_response_time);
            apiAnalytics.fireApiResponse();
        }
        // send first heart beat
        // PUT PLAYBACK DATA IN STORE
        const payload = {
            [contentId]: {
                ...playbackDetails,
                expireCount: Infinity, // default n number of times OLD playback call can be used
            },
        };
        mockPlaybackNadHeartbeatError();
        yield put(playbackDetailsFetchCompletedAction({ payload }));
    }
    catch (e) {
        console.error(e);
        let apiResponseTime;
        if (e.customResponse) {
            apiResponseTime = e.api_response_time;
            e = e.message;
        }
        else {
            apiResponseTime = e.api_response_time;
        }
        let error = e.data ? e.data : e;

        const { headers, request } = e || {};
        if (!isOnline()) {
            error = { errorcode: ERROR_CODES.PLAYER_NETWORK_ERROR };
        }

        const payload = {
            [contentId]: {
                error,
                fetching: false,
            },
        };

        const { errorcode } = error;
        playbackErrorGaEvent({ errorcode });

        if (error) {
            ErrorHandler({
                code: error.errorcode,
                errorTitle: error.appErrorMessage,
                contentId,
                error,
                isTrailer,
                doNotHandle: error.errorcode === ERROR_CODES.ATV999,
                // resetLayout: true,
            });
        }

        // IF ACCESS TOKEN IS EXPIRED THEN REFRESH TOKEN AND RETRY IF ITS NOT ALREADY DONE (FLAG: closeRecursion)
        if (error.errorcode === ERROR_CODES.ATV043 && !closeRecursion) {
            if (!isTrailerTile) {
                apiAnalytics.setResponseTime(apiResponseTime);
                apiAnalytics.fireApiResponse(error, headers, request);
            }
            yield call(refreshTokenRetryPlaybackSaga, {
                contentId,
                callback: fetchPlaybackWithoutLogin,
                errorActionSaga: playbackDetailsFetchErrorAction,
                callbackParam: {
                    isTrailerTile,
                    isTrailer,
                },
            });
        }
        // SOME ERROR OCCURRED SO PUT ERROR IN STORE
        else {
            if (!isTrailerTile) {
                apiAnalytics.setResponseTime(apiResponseTime);
                apiAnalytics.fireApiResponse(error, headers, request);
            }
            yield put(playbackDetailsFetchErrorAction({ payload }));
        }
    }
}


function* showCanNotPlayVideoPopup(payload) {
    const { isAuthenticated, uid } = yield select(getAuthConfigFromReducer);
    if (isAuthenticated) {
        yield put(showModalComponentAction({
            showModalValue: true,
            componentNameValue: MODAL_POPUP.CANNOT_PLAY_VIDEO_POPUP,
            overrideCrossButtonValue: true,
            payload,
        }));
    }
}

export default [
    takeFirst(PlaybackDetailsActions.PLAYBACK_DETAILS_FETCH, fetchPlaybackDetails),
    takeFirst(PlaybackDetailsActions.PLAYBACK_DETAILS_COOKIES_FETCH, fetchPlaybackCookie),
    takeFirst(PlaybackDetailsActions.SEND_HEART_BEAT, sendHeartbeatSaga),
    takeFirst(PlaybackDetailsActions.PLAYBACK_WITHOUT_LOGIN_DETAILS_FETCH, fetchPlaybackWithoutLogin),
    takeFirst(PlaybackDetailsActions.CANNOT_PLAY_VIDEO, showCanNotPlayVideoPopup),
    takeFirst(PlaybackDetailsActions.APP_IN_APP_HANDLING, handleAppInAppRedirection),
];
