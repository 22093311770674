let queue = [];
let instance;


export class PlayerAnalyticsQueue {
    static allowAnalyticsEventsQueue = false;

    static startAnalyticsEventsQueue() {
        PlayerAnalyticsQueue.allowAnalyticsEventsQueue = true;
    }

    static stopAnalyticsEventsQueue() {
        PlayerAnalyticsQueue.allowAnalyticsEventsQueue = false;
    }

    constructor() {
        if (!instance) {
            instance = this;
        }
        return instance;
    }

    pushEvent = (fnCallback) => {
        if (!PlayerAnalyticsQueue.allowAnalyticsEventsQueue) {
            fnCallback();
            return;
        }
        queue.push(fnCallback);
    };

    flushEvent = (fnCallbackRef) => {
        if (fnCallbackRef) {
            fnCallbackRef();
        }
    };

    flushAllEvents = () => {
        queue.forEach(fn => this.flushEvent(fn));
        this.disposeQueue();
    };

    disposeQueue = () => {
        queue = [];
    };
}

export default new PlayerAnalyticsQueue();
